<template>
  <div class="d-flex full">
    <v-btn @click="handleClickImport">
      Launch import
    </v-btn>
    <!-- <v-btn @click="testClick">
      Tests
    </v-btn> -->
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  dosmthing () {
    this.loading = false
  },
  components: {
  },
  data () {
    return {
      loading: false
    }
  },
  watch: {
    loading (val) {
      // if (!val) return
    }
  },
  computed: {
  },
  props: {
    disabled: Boolean,
    editAmountOnly: Boolean
  },
  methods: {
    async handleClickImport () {
      this.loading = true
      await this.$http().post('/core/import')
      this.loading = false
    },
    async testClick () {
    }
  }
}
</script>

<style lang="stylus" scoped>
>>> tr
  cursor pointer
</style>
